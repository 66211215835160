import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import styled, { css } from 'styled-components';


const BeerListItem = ({
  beer,
}) => {



  return (
    <Container style={{backgroundColor: beer.beerPrimaryColor}}>
      <LazyLoadImage 
        src={beer.beerCanImage[0].url}
        alt={'Photo of can containing ' + beer.title}
      />
      {/* <img src={beer.beerCanImage[0].url} alt=""/> */}
      <DetailsWrapper>
        <h3>{beer.title}</h3>
        <DescriptorContainer>
          <p className={'bebas'} style={{color: beer.fontColor}}>{beer.descriptor}</p>
          <p className={'bebas'} style={{color: beer.fontColor}}>{beer.abv} ABV</p>
        </DescriptorContainer>
        <div className='rich-container' style={{color: beer.fontColor}} dangerouslySetInnerHTML={{__html: beer.details}}></div>
        {
          beer.shopLink && beer.showOrderButton && (
            <CtaLink href={beer.shopLink} target="_blank">Order Now</CtaLink>
          )
        }
      </DetailsWrapper>
    </Container>
  )
};

BeerListItem.propTypes = {

};

export default BeerListItem;

const Container = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 0;
  img {
    width: 100%;
    height: auto;
    max-width: 250px;
  }


  @media all and (min-width: 750px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8em 0;
    img {
      width: 40%;
      max-width: 350px;
    }
    &:nth-of-type(even) {
      img {
        order: 2;
      }
      > div {
        order: 1;
      }
    }
    &:last-of-type {
      padding-bottom: 2em;
    }
  }
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -6em;
  h3 {
    display: block;
    color: white;
    font-family: 'Averia Serif Libre', serif;
    text-transform: uppercase;
    font-size: 6em;
    text-align: center;
    width: 100%;
  }
  > p {
    color: ${({ theme }) => theme.colors.Primary};
    padding: 0 1.5em;
    width: 100%;
  }
  div.rich-container > p {
    margin-bottom: 1em;
    padding: 0 1.5em;
  }
  @media all and (min-width: 750px) {
    width: 40%;
    align-content: flex-start;
    margin-top: 3em;
  }
  @media all and (min-width: 900px) {
    h3 {
      font-size: 8em;
    }
  }
  @media all and (min-width: 1100px) {
    h3 {
      font-size: 10.5em;
    }
  }
`;

const DescriptorContainer = styled.div`
  display: flex;
  margin-bottom: 2em;
  p {
    color: ${({ theme }) => theme.colors.Primary};
    position: relative;
    font-size: 1.8em;
    letter-spacing: 3px;
    @media all and (min-width: 500px) {
      font-size: 2.2em;
    }
  }
  p:first-of-type {
    margin-right: 40px;
  }


  p:first-of-type::after {
    content:"";
    background-image: url('/icons/tee.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 100%;
    width: 5px;
    position: absolute;
    top: 3px;
    right: -20px;
  }

`;

const CtaLink = styled.a`
  font-family: 'Bebas Neue', serif;
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.Primary};
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  align-self: center;
  padding: 0.2em 1em;
  padding-top: 0.35em;
  margin: 2em 0;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  &:hover { 
    background-color: ${({ theme }) => theme.colors.Primary};
    color: white;
  }
`;
