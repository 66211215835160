import React, { memo } from 'react';

import styled, { css } from 'styled-components';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import SectionHeadline from 'components/elements/SectionHeadline';
import BeerListItem from 'components/blocks/BeerListItem';
import Button from 'components/elements/Button';

import { toggleMap } from 'actions/map';

import { navigate } from 'gatsby';


const Clubhouse = ({
  beers,
  mapIsOpen,
  dispatch,
  onClubhousePage
}) => {

  const handleCtaClick = () => {
    navigate('/clubhouse')
  };


  return (
    <Container>
      <Headline onClubhousePage={onClubhousePage}>Clubhouse</Headline>
      <BeerList>
        {
          beers.map((beer) => (
            <BeerListItem beer={beer} key={beer.title}/>
          ))
        }
      </BeerList>
      <CtaContainer>
        
        <StyledButton onClick={handleCtaClick}>
          <span className={'bebas'}>View All Beers</span>
        </StyledButton>

      </CtaContainer>
    </Container>
  )
};

Clubhouse.propTypes = {
  mapIsOpen: PropTypes.bool
};

const mapStateToProps = ({ map }) => ({
  mapIsOpen: map.mapIsOpen,
});
const withConnect = connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
);
export default compose(
  memo,
  withConnect,
)(Clubhouse);


const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.Primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6em;
  @media all and (min-width: 750px) {
    padding-top: 0em;
  }
`;

const Headline = styled(SectionHeadline)`
  @media all and (min-width: 750px) {
    ${( { onClubhousePage } ) =>
      !onClubhousePage &&
      css`
        background-color: ${({ theme }) => theme.colors.Primary};
        padding: 3em 4em;
        transform: translateY(-50%);
        &:before {
          top: 35%;
          left: 20%;
        }
        &:after {
          bottom: 32.5%;
        }
      `
    };
    ${( { onClubhousePage } ) =>
      onClubhousePage &&
      css`
        margin: 3em 0;
      `
    };
  }

  @media all and (min-width: 900px) {
    h2 {
      font-size: 4em;
    }
  }
`;

const BeerList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.DarkYellow};
  padding: 6em 0;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.BrightYellow};

  img {
    width: 30px;
    height: auto;
    margin-right: 0.75em;
  }
  span {
    color: ${({ theme }) => theme.colors.Primary};
    border: 1px solid ${({ theme }) => theme.colors.Primary};
    padding: 0.25em 0.5em;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    span {
      background-color: ${({ theme }) => theme.colors.Primary};
      color: ${({ theme }) => theme.colors.BrightYellow};
    }
  }
`;

