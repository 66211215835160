import React  from 'react';

import styled, { css }  from 'styled-components';

const SectionHeadline = ({
  children,
  className,
  greenText
}) => {



  return (
    <Container greenText={greenText} className={className}>
      <StyledSectionHeadline>
        {children}
      </StyledSectionHeadline>
    </Container>
  )
};

SectionHeadline.propTypes = {

};

export default SectionHeadline;

const Container = styled.div`
  position: relative;
  z-index: 3;
  padding: 2em 0;

  &:before,
  &:after {
    content:"";
    width: 42.5%;
    height: 2em;
    position:absolute;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:before {
    background-image: url('/icons/left-headline-arrow.svg');
    top: 27.5%;
    left: 17.5%;
    transform: translateY(-50%);
  }
  &:after {
    background-image: url('/icons/right-headline-arrow.svg');
    bottom: 27.5%;
    right: 15%;
    transform: translateY(50%);
  }
  ${( { greenText } ) =>
    greenText &&
    css`
      background-color: white;
      padding: 3em 4em;
      &:before {
        top: 30%;
        left: 20%;
        background-image: url('/icons/left-headline-arrow-primary.svg');
      }
      &:after {
        bottom: 32.5%;
        background-image: url('/icons/right-headline-arrow-primary.svg');
      }
      h2 {
        color: ${({ theme }) => theme.colors.Primary};
      }
    `
  };
`;


const StyledSectionHeadline = styled.h2`
  font-family: 'Averia Serif Libre', serif;
  color: white;
  transform: skew(-5deg,-10deg);
  text-transform: uppercase;
`;
