/**
 *
 * IndexPage
 *
 *
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import { graphql } from "gatsby";

import Clubhouse from 'components/sections/Clubhouse';



const ClubhousePage = ({ data }) => {
  useEffect(() => {

  });


  const beers = data.clubhouse['nodes'];
  
  return (
    <Container>
      <Clubhouse beers={beers} onClubhousePage={true} />
    </Container>
  );
};

ClubhousePage.propTypes = {

};

export default ClubhousePage;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`;

export const pageQuery = graphql`
  {
    clubhouse: allCraftClubhouseClubhouseEntry(sort: {fields: lft, order: ASC}) {
      nodes {
        ... on Craft_clubhouse_clubhouse_Entry {
          id
          title
          abv
          descriptor
          beerPrimaryColor
          fontColor
          details
          beerCanImage {
            url
          }
          shopLink
          showOrderButton
        }
      }
    }
  }
`;